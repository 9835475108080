<template>
  <div>
    <b-table :data="data" :per-page="100" :paginated=paginated :scrollable="true" :width="80">
      <b-table-column field="userID" label="Username" :searchable="searchable" v-slot="props">
        <div class="tooltip" v-on:click="goToUser(scriptId, props.row.username)">
          <div style="font-size: small;">{{ props.row.username }}</div>
<!--          <span class="tooltiptext">{{ props.row.userID }}</span>-->
        </div>
      </b-table-column>

      <b-table-column v-for="col in columnOptions" v-bind:key="col.resourceName"
                      :field="'resources.' + col.resourceName" :label="col.resourceName" :numeric="true" v-slot="props" sortable>
        <div v-if="isRuntime(col.resourceName)" style="font-size: small;">{{[props.row.resources[col.resourceName], 'seconds'] | duration('humanize') }}</div>
        <div v-if="!isRuntime(col.resourceName)" style="font-size: small;">{{ Number(props.row.resources[col.resourceName]).toLocaleString() }}</div>
      </b-table-column>

    </b-table>
  </div>
</template>

<script>

export default {
  name: "StatsTable",
  props: ['scriptId', 'data', 'columnOptions', 'paginated', 'searchable'],
  methods: {
    goToUser(scriptId, username) {
      console.log(scriptId, username);
      // router.push(`/scripts/${scriptId}/users/${username}`)
    },
    isRuntime(str) {
      if (str.toLowerCase().startsWith("runtime")) return true;
      if (str.toLowerCase().startsWith("run time")) return true;
      if (str.toLowerCase().startsWith("run-time")) return true;
      return false;
    }
  }
}
</script>

<style>
.tooltip {
  position: relative;
  display: inline-block;
  /*cursor: pointer;*/
}

.is-relative {
  font-size: small;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 300px;
  background-color: #535353;
  color: #fff;
  text-align: center;
  font-size: small;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 0;
  left: 115%;

  opacity: 0;
  transition: opacity 1s;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}
</style>