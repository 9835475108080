<template>
  <div style="padding-left: 30px;padding-right: 30px;" v-if="currentScript">
    <div class="script-name">{{ currentScript.name }}</div>
    <div class="script-owner">by {{ currentScript.owner }}</div>
    <div style="display: flex; flex-direction: row; align-items: center; justify-content: center; margin-bottom: 20px">
      <div v-on:click="goToEdit" class="edit-script-button">Edit Script</div>
      <div class="button-divider">|</div>
      <a class="signature-button" :href="getSignatureUrl()">Signature Image</a>
      <div class="button-divider">|</div>
      <a class="signature-button" :href="getLeaderboardUrl()">Public Leaderboards</a>
      <div class="button-divider">|</div>
      <a class="documentation-button" :href="getGithubUrl()"
         v-bind:class="{ 'document-button-glow': Object.keys(currentScript).length !== 0 && Object.keys(userGroupedData).length === 0 }">Documentation</a>
    </div>

    <div class="subtitle-heading">
      <div class="subtitle-label">Api Key</div>
      <div class="subtitle">{{ currentScript.id }}</div>
    </div>

    <div class="subtitle-heading">
      <div class="subtitle-label">Secret</div>
      <div v-if="!showSecret" class="toggle-secret-button" v-on:click="toggleShowSecret">Show Secret</div>
      <div v-if="showSecret" class="subtitle">{{ currentScript.secret }}</div>
    </div>

    <div class="subtitle-heading">
      <div class="subtitle-label">Password</div>
      <div v-if="!showPassword" class="toggle-secret-button" v-on:click="toggleShowPassword">Show Password</div>
      <div v-if="showPassword" class="subtitle">{{ currentScript.password }}</div>
    </div>

    <highcharts :options="chartOptions"></highcharts>

    <div class="table-title">Top Users</div>

    <div style="display: flex; justify-content: center">
      <v-select :options="dropdownOptions" style="width: 250px;"
                @input="pieFilterSelected"></v-select>
    </div>

    <highcharts :options="pieOptions"></highcharts>

    <div class="table-title">User Breakdown</div>

    <stats-table :script-id="currentScript.id" :data="tableData" :columnOptions="tableColumns"
                 :searchable="true"></stats-table>

    <div class="online-users-title">Online Users ( {{ onlineUsers.length }} )</div>
    <div v-for="onlineStatus in onlineUsers" :key="onlineStatus.userID">
      <div style="display: flex; flex-direction: row; justify-content: center">
        <div class="online-user" v-on:click="goToUser(currentScript.id, onlineStatus.userID)">{{ parsedUsername(onlineStatus.userID) }}</div>
      </div>
    </div>

    <div style="margin-bottom: 100px"></div>

  </div>
</template>

<script>
import store from "../../store";
import router from "../../router"
import {Chart} from 'highcharts-vue'
import StatsTable from "@/components/StatsTable";
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';

export default {
  name: "ScriptView",
  created() {
    const id = this.$route.params.scriptId;
    const password = store.getters.getScriptPasswordById(id);

    this.getScriptValidate(id, password).then(value => {
      this.currentScript = value.data;
    }).catch(reason => alert(reason));

    this.getScriptActivity(id, password).then(value => {
      this.generateSeries(value.data);

      this.userGroupedData = this.addAllTimeseriesByUser(value.data);
      if (Object.keys(this.userGroupedData).length > 0) {
        this.dropdownOptions = Object.keys(this.userGroupedData);
        this.pieOptions.series = [{
          name: this.dropdownOptions[0],
          colorByPoint: true,
          data: this.transformToPieChart(this.userGroupedData, this.dropdownOptions[0]),
        }];
        this.pieOptions.title.text = `Chart for ${this.dropdownOptions[0]}`;
      }
    });

    this.getOnlineUsers(id, password).then(value => {
      this.onlineUsers = value.data;
      console.log(this.onlineUsers);
    }).catch(reason => alert(reason));
  },
  data() {
    return {
      currentScript: {},
      getSignatureUrl() {
        const host = process.env.VUE_APP_API_URL
        if (host != null) {
          return `${host}/api/scripts/${this.$route.params.scriptId}/signature`
        }
        return `/api/scripts/${this.$route.params.scriptId}/signature`
      },
      getLeaderboardUrl() {
        return `/scripts/${this.$route.params.scriptId}/public`
      },
      getGithubUrl() {
        return `https://github.com/itsdax/DaxTracker/wiki/Getting-Started`
      },
      showSecret: false,
      showPassword: false,
      tableData: [],
      dropdownOptions: [],
      onlineUsers: [],
      userGroupedData: {},
      tableColumns: [],
      chartOptions: {
        title: {text: 'Total Resources Tracked'},
        subtitle: {text: 'All Users'},
        yAxis: {title: {text: 'Units'}},
        xAxis: {
          type: 'datetime',
          labels: {
            format: '{value:%b %d %Y}',
            rotation: 45,
          },
        },
        series: [],
        chart: {zoomType: 'x'},
      },
      pieOptions: {
        title: {text: ' '},
        chart: {type: 'pie'},
        // tooltip: {pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'},
        // accessibility: {point: {valueSuffix: '%'}},
        plotOptions: {
          pie: {
            allowPointSelect: true,
            cursor: 'pointer',
            dataLabels: {
              enabled: true,
              format: '<b>{point.name}</b>: {point.percentage:.1f} %'
            }
          }
        },
        series: []
      }
    }
  },
  methods: {
    goToUser(scriptId, username) {
      console.log(scriptId, username)
    },
    pieFilterSelected(resourceName) {
      this.pieOptions.series = [{
        name: resourceName,
        colorByPoint: true,
        data: this.transformToPieChart(this.userGroupedData, resourceName),
      }];
      this.pieOptions.title.text = `Chart for ${resourceName}`;
    },
    generateSeries(data) {
      if (data == null) return [];

      // ===== GRAPH GENERATION =====
      // Transform to timestamp[resources]
      const resources = [];
      data.forEach(activity => {
        resources.push(activity.resources);
      })
      const aggregate = this.aggregateTimeseries(resources);
      this.chartOptions.series = this.transformTimeseriesToGraphable(aggregate);


      // ===== TABLE GENERATION =====
      const activities = [];
      const consolidateActivities = new Map();
      data.forEach(activity => {
        const username = this.parsedUsername(activity.userID)
        if (consolidateActivities[username] == null) {
          consolidateActivities[username] = this.addAllTimeseries(activity.resources)
        } else {
          consolidateActivities[username] = this.combineAddedTimeSeries(consolidateActivities[username], this.addAllTimeseries(activity.resources))
        }
      });

      Object.keys(consolidateActivities).forEach(username => {
        activities.push({
          username: username,
          resources: consolidateActivities[username],
        })
      })

      const columns = [];
      const resourceMapping = this.transformTimeseriesToResourceMapping(aggregate);
      Object.keys(resourceMapping).forEach(resourceName => {
        columns.push({
          resourceName: resourceName,
        })
      });

      this.tableData = activities;
      this.tableColumns = columns;
    },
    goToEdit() {
      router.push('/scripts/' + this.$route.params.scriptId + "/edit")
    },
    toggleShowSecret() {
      this.showSecret = !this.showSecret;
    },
    toggleShowPassword() {
      this.showPassword = !this.showPassword;
    }
  },
  components: {
    StatsTable,
    highcharts: Chart,
    'v-select': vSelect,
  }
}
</script>

<style scoped>

.script-name {
  font-size: 48px;
  text-align: center;
}

.script-owner {
  font-size: x-large;
  text-align: center;
  margin-bottom: 10px;
}

.edit-script-button {
  font-size: small;
  cursor: pointer;
  color: #6f6f6f;
}

.signature-button {
  font-size: small;
  color: #6f6f6f;
  text-decoration: none;
}

.documentation-button {
  font-size: small;
  color: #6f6f6f;
  text-decoration: none;
  transition: all 0.2s ease-in;
  text-shadow: none;
}

.document-button-glow {
  color: #d658ff;
  font-weight: 700;
  text-shadow: 0 0 30px #ffdede, 0 0 30px pink, 0 0 30px pink, 0 0 10px #ffdede;
}

.document-button-glow:hover {
  font-size: medium;
  text-shadow: 0 0 30px #d5ffe8, 0 0 30px #d5ffe8, 0 0 30px #d5ffe8, 0 0 10px #d5ffe8;
}

.button-divider {
  font-size: small;
  color: #6f6f6f;
  margin: 0 8px;
}

.subtitle {
  font-size: small;
  color: #6f6f6f;
}

.subtitle-heading {
  display: flex;
  flex-direction: row;
}

.subtitle-label {
  font-size: small;
  margin-right: 10px;
  width: 100px;
}

.toggle-secret-button {
  font-size: small;
  cursor: pointer;
}

.table-title {
  font-size: 20px;
  text-align: center;
  margin: 80px 0 20px 0;
}

.online-users-title {
  margin: 180px 0 20px 0;
  font-size: 20px;
  text-align: center;
}

.online-user {
  /*cursor: pointer;*/
  margin-bottom: 12px;
  transition: all 0.2s ease-in;
}

.online-user:hover {
  text-shadow: 0 0 30px #0c9900, 0 0 30px #c4ffc0, 0 0 30px #48c902, 0 0 10px #84f880;
}

</style>