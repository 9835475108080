export default {
    methods: {
        // Adds a map of timeseries to a single object
        addAllTimeseries(timeseries) {
            const totals = {};
            Object.keys(timeseries).forEach(timestamp => {
                Object.keys(timeseries[timestamp]).forEach(resourceName => {
                    if (totals[resourceName] == null) {
                        totals[resourceName] = 0;
                    }
                    totals[resourceName] += timeseries[timestamp][resourceName];
                });
            });
            return totals;
        },

        // used to combine the outputs of addAllTimeseries
        combineAddedTimeSeries(ts1, ts2) {
            const result = {};
            Object.keys(ts1).forEach(resourceName => {
                if (result[resourceName] == null) {
                    result[resourceName] = 0;
                }
                result[resourceName] += ts1[resourceName]
            })
            Object.keys(ts2).forEach(resourceName => {
                if (result[resourceName] == null) {
                    result[resourceName] = 0;
                }
                result[resourceName] += ts2[resourceName]
            })
            return result
        },

        // map[resourceName]map[userId]resourceValue
        addAllTimeseriesByUser(activities) {
            const totals = {};
            activities.forEach(activity => {
                Object.keys(activity.resources).forEach(timestamp => {
                    Object.keys(activity.resources[timestamp]).forEach(resourceName => {
                        if (totals[resourceName] == null) {
                            totals[resourceName] = {};
                        }
                        if (totals[resourceName][activity.userID] == null) {
                            totals[resourceName][activity.userID] = 0;
                        }
                        totals[resourceName][activity.userID] += activity.resources[timestamp][resourceName];
                    });
                });

            });
            return totals;
        },

        // map[resourceName]map[userId]resourceValue => [{name: <string>, value: <int>}, ...]
        transformToPieChart(values, resourceName) {
            const data = [];
            const consolidateUsers = new Map();
            Object.keys(values[resourceName]).forEach(userID => {
                if (consolidateUsers[this.parsedUsername(userID)] == null) {
                    consolidateUsers[this.parsedUsername(userID)] = 0;
                }
                consolidateUsers[this.parsedUsername(userID)] += values[resourceName][userID];
                // data.push({
                //     name: this.parsedUsername(userID),
                //     y: values[resourceName][userID],
                // })
            });
            console.log(consolidateUsers);
            Object.keys(consolidateUsers).forEach(username => {
                data.push({
                    name: username,
                    y: consolidateUsers[username],
                })
            })

            return data;
        },


        // Timeseries example:  1610841600: {batman: 30, coolio: 10}
        aggregateTimeseries(timeseriesList) {
            const totals = {};
            timeseriesList.forEach(timeseries => {
                Object.keys(timeseries).forEach(timestamp => {
                    if (totals[timestamp] == null) {
                        totals[timestamp] = {};
                    }

                    Object.keys(timeseries[timestamp]).forEach(resourceName => {
                        if (totals[timestamp][resourceName] == null) {
                            totals[timestamp][resourceName] = 0;
                        }

                        totals[timestamp][resourceName] += timeseries[timestamp][resourceName];
                    });
                });
            })

            return totals;
        },

        transformTimeseriesToResourceMapping(timeseries) {
            const resourceMapping = {};
            Object.keys(timeseries).forEach(timestamp => {
                Object.keys(timeseries[timestamp]).forEach(resourceName => {
                    if (resourceMapping[resourceName] == null) {
                        resourceMapping[resourceName] = {
                            name: resourceName,
                            data: [],
                        }
                    }

                    const resourceValue = timeseries[timestamp][resourceName];
                    resourceMapping[resourceName].data.push([timestamp * 1000, resourceValue]);
                });
            });
            return resourceMapping;
        },

        // transforms to {name: <resourceName>, data: [tuple<timestamp, value>]
        transformTimeseriesToGraphable(timeseries) {
            const resourceMapping = this.transformTimeseriesToResourceMapping(timeseries);

            const transformed = [];
            Object.keys(resourceMapping).forEach(resourceName => {
                transformed.push(resourceMapping[resourceName]);
            });

            return transformed;
        },

        parsedUsername(userID) {
            const first = userID.substr(0, userID.indexOf(':'));
            return first ? first : userID;
        },
    }
}