import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        scripts: new Map(),
        loadedScripts: [],
    },
    mutations: {
        addScript(state, script) {
            state.scripts[script.id] = script.password;
        },
        addLoadedScript(state, script) {
            state.loadedScripts.push(script);
        },
        emptyLoadedScripts(state) {
            state.loadedScripts = [];
        },
    },
    getters: {
        getScripts(state) {
            return state.scripts;
        },
        getLoadedScripts(state) {
            return state.loadedScripts;
        },
        getScriptPasswordById(state) {
            return id => state.scripts[id];
        },
    },
    plugins: [createPersistedState()],
})
