<template>
  <div style="display: flex; flex-direction: column; align-content: center; justify-content: center;">
    <div v-if="!publicLeaderboards" class="table-title">
      {{publicLeaderboardsMessage}}
    </div>
    <div v-if="publicLeaderboards" style="display: flex; flex-direction: column">
      <div class="table-title">Top Users</div>

      <div style="display: flex; justify-content: center">
        <v-select :options="dropdownOptions" style="width: 250px;"
                  @input="pieFilterSelected"></v-select>
      </div>
      <highcharts :options="pieOptions"></highcharts>


      <div class="table-title">User Breakdown</div>

      <stats-table :script-id="$route.params.scriptId" :data="tableData" :columnOptions="tableColumns"
                   :searchable="true"></stats-table>
    </div>
  </div>
</template>
<script>

import StatsTable from "@/components/StatsTable";
import {Chart} from "highcharts-vue";
import vSelect from 'vue-select'

export default {
  name: "ScriptView",

  created() {
    const id = this.$route.params.scriptId;
    this.getScriptActivityPublic(id).then(value => {
      this.publicLeaderboards = true;
      this.generateSeries(value.data);

      this.userGroupedData = this.addAllTimeseriesByUser(value.data);
      if (Object.keys(this.userGroupedData).length > 0) {
        this.dropdownOptions = Object.keys(this.userGroupedData);
        this.pieOptions.series = [{
          name: this.dropdownOptions[0],
          colorByPoint: true,
          data: this.transformToPieChart(this.userGroupedData, this.dropdownOptions[0]),
        }];
        this.pieOptions.title.text = `Chart for ${this.dropdownOptions[0]}`;
      }
    }).catch(reason => {
      console.log(reason);
      this.publicLeaderboardsMessage = "Public leaderboards are disabled"
      this.publicLeaderboards = false;
    });
  },
  data() {
    return {
      publicLeaderboards: false,
      publicLeaderboardsMessage: "Loading",
      currentScript: null,
      tableData: [],
      dropdownOptions: [],
      onlineUsers: [],
      userGroupedData: {},
      tableColumns: [],
      chartOptions: {
        title: {text: 'Total Resources Tracked'},
        subtitle: {text: 'All Users'},
        yAxis: {title: {text: 'Units'}},
        xAxis: {
          type: 'datetime',
          labels: {
            format: '{value:%b %d %Y}',
            rotation: 45,
          },
        },
        series: [],
        chart: {zoomType: 'x'},
      },
      pieOptions: {
        title: {text: ' '},
        chart: {type: 'pie'},
        // tooltip: {pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'},
        // accessibility: {point: {valueSuffix: '%'}},
        plotOptions: {
          pie: {
            allowPointSelect: true,
            cursor: 'pointer',
            dataLabels: {
              enabled: true,
              format: '<b>{point.name}</b>: {point.percentage:.1f} %'
            }
          }
        },
        series: []
      }
    }
  },
  methods: {
    pieFilterSelected(resourceName) {
      this.pieOptions.series = [{
        name: resourceName,
        colorByPoint: true,
        data: this.transformToPieChart(this.userGroupedData, resourceName),
      }];
      this.pieOptions.title.text = `Chart for ${resourceName}`;
    },
    generateSeries(data) {
      if (data == null) return [];

      // ===== GRAPH GENERATION =====
      // Transform to timestamp[resources]
      const resources = [];
      data.forEach(activity => {
        resources.push(activity.resources);
      })
      const aggregate = this.aggregateTimeseries(resources);
      this.chartOptions.series = this.transformTimeseriesToGraphable(aggregate);


      // ===== TABLE GENERATION =====
      const activities = [];
      const consolidateActivities = new Map();
      data.forEach(activity => {
        const username = this.parsedUsername(activity.userID)
        if (consolidateActivities[username] == null) {
          consolidateActivities[username] = this.addAllTimeseries(activity.resources)
        } else {
          consolidateActivities[username] = this.combineAddedTimeSeries(consolidateActivities[username], this.addAllTimeseries(activity.resources))
        }
      });

      Object.keys(consolidateActivities).forEach(username => {
        activities.push({
          username: username,
          resources: consolidateActivities[username],
        })
      })

      const columns = [];
      const resourceMapping = this.transformTimeseriesToResourceMapping(aggregate);
      Object.keys(resourceMapping).forEach(resourceName => {
        columns.push({
          resourceName: resourceName,
        })
      });

      this.tableData = activities;
      this.tableColumns = columns;
    },
  },
  components: {
    StatsTable,
    highcharts: Chart,
    'v-select': vSelect,

  }
}
</script>

<style scoped>

.table-title {
  font-size: 20px;
  text-align: center;
  margin: 80px 0 20px 0;
}

</style>