<template>
  <div style="display: flex; flex-direction: column; align-items: center;">
    <div style="font-size: xx-large; margin-bottom: 20px">Sign Up</div>
    <form class="create-script-contents">
      <label style="margin: 60px 0 0 0; text-align: center; font-size: small">
        Script Name
        <input ref="name" placeholder="Script Name">
      </label>
      <label style="text-align: center; font-size: small">
        Script Owner
        <input ref="owner" placeholder="Your Name">
      </label>
      <button @click.prevent="postCreateScript()">Create</button>
    </form>
  </div>
</template>

<script>
import store from "../../store";
import router from '../../router'

export default {
  name: "CreateScript",
  methods: {
    postCreateScript() {
      this.createScript(
          this.$refs.name.value,
          this.$refs.owner.value,
      ).then(value => {
        store.commit('addScript', value.data)
        router.push('/')
      }).catch(reason => alert(reason))
    },
  }
}
</script>

<style scoped>
.create-script-contents {
  max-width: 300px;
}

input {
  font-size: large;
  font-weight: 300;
  width: 100%;
  padding: 8px 15px;
  border-radius: 5px;
  border: 1px solid #bdbdbd;
  background-color: rgba(255,255,255, 0.7);

  margin-bottom: 5px;
}

button {
  font-size: large;
  color: white;
  background: #5bb6f8;
  border: none;
  padding: 7px 15px;
  font-weight: 700;
  border-radius: 3px;
  cursor: pointer;

  margin-top: 30px;
  width: 100%;
}

</style>
