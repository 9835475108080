import Vue from 'vue'
import Router from 'vue-router'
import AddScript from "../components/script-viewer/AddScript";
import CreateScript from "../components/script-viewer/CreateScript";
import ScriptEditor from "../components/script-dashboard/ScriptEditor";
import ScriptView from "../components/script-dashboard/ScriptView";
import ScriptsHome from "../components/script-viewer/ScriptsHome";
import UserView from "@/components/user-viewer/UserView";
import PublicView from "@/components/public-view/PublicView";

Vue.use(Router)

export default new Router({
    mode: 'history',
    routes: [
        {
            path: '/',
            name: 'ScriptsHome',
            component: ScriptsHome
        },
        {
            path: '/add_script',
            name: 'AddScript',
            component: AddScript
        },
        {
            path: '/create_script',
            name: 'CreateScript',
            component: CreateScript
        },
        {
            path: '/scripts/:scriptId',
            name: 'ScriptView',
            component: ScriptView
        },
        {
            path: '/scripts/:scriptId/public',
            name: 'PublicView',
            component: PublicView
        },
        {
            path: '/scripts/:scriptId/edit',
            name: 'ScriptEditor',
            component: ScriptEditor
        },
        {
            path: '/scripts/:scriptId/users/:userId',
            name: 'UserView',
            component: UserView
        },
    ]
})
