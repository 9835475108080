import axios from 'axios'
import store from '../store'

export const HOST = process.env.VUE_APP_API_URL;

export default {
    methods: {
        createScript(scriptName, ownerName) {
            let payload = {name: scriptName, owner: ownerName};
            return axios.post(HOST + '/api/scripts', payload)
        },
        updateScript(scriptID, scriptUpdateRequest) {
            const password = store.getters['getScriptPasswordById'](scriptID);
            if (password == null) {
                alert('You are not authorized to make changes to this script')
                return;
            }
            return axios.post(`${HOST}/api/scripts/${scriptID}`, scriptUpdateRequest, {headers: {'Password': password}})
        },
        getScript(id) {
            return axios.get(`${HOST}/api/scripts/${id}/validate`);
        },
        getUserViaScriptCredentials(id, scriptId, password) {
            return axios.get(`${HOST}/api/users/${id}`, {headers: {'scriptID': scriptId, 'Password': password}});
        },
        getScriptValidate(id, password) {
            return axios.get(`${HOST}/api/scripts/${id}/validate`, {headers: {'Password': password}});
        },
        getScriptActivityPublic(id) {
            return axios.get(`${HOST}/api/scripts/${id}/public_activities`);
        },
        getOnlineUsers(id, password) {
            return axios.get(`${HOST}/api/scripts/${id}/online`, {headers: {'Password': password}});
        },
        getScriptActivity(id, password) {
            return axios.get(`${HOST}/api/scripts/${id}/activities`, {headers: {'Password': password}});
        },
        getUserActivity(scriptId, userId, password) {
            return axios.get(`${HOST}/api/users/${userId}/activities`, {headers: {'scriptID': scriptId, 'Password': password}});
        },
        login(id, secret) {
            let payload = {userID: id, secretKey: secret};
            return axios.post(HOST + '/api/login', payload)
        }
    }
}
