<template>
  <div v-if="currentScript">
    <div class="edit-script-title">Updating Script</div>
    <form style="display: flex; flex-direction: column; padding: 0 50px">
      <label>
        Update Script Name
        <input ref="name" type="text" v-bind:value="currentScript.name">
      </label>
      <label>
        Update Owner
        <input ref="owner" type="text" v-bind:value="currentScript.owner">
      </label>

      <label style="width: 325px; margin-bottom: 20px">
        Update Signature Image (Max size: 500kb)
        <input @change="onFileSelected" type="file" id="img" name="img" accept="image/*">
      </label>
      <div v-if="currentScript.image" style="display: flex; justify-content: center">
        <img v-bind:src="'data:' + currentScript.imageContentType + ';base64,' + currentScript.image"
             alt="I cannot be displayed">
      </div>
      <label style="margin-top: 40px">
        Update Signature Draw Location <a
          href="https://github.com/itsdax/DaxTracker/wiki/Updating-Signature-Draw-Locations" style="cursor: pointer">(See
        Documentation and Examples)</a>
        <textarea ref="displayLocations" v-bind:value="getDisplayLocationConfig()"></textarea>
      </label>
      <label style="margin-top: 40px">
        Update Username Location <a href="https://github.com/itsdax/DaxTracker/wiki/Update-Username-Location"
                                    style="cursor: pointer">(See Documentation and Examples)</a>
        <textarea ref="usernameDisplayLocation" v-bind:value="getUsernameLocationConfig()"></textarea>
      </label>

      <div style="flex-direction: row; display: flex; margin-bottom: 20px">
        <input style="margin: 5px 20px 5px 5px; width: 10px"  type="checkbox" id="checkbox" v-model="publicLeaderboard">
        <label for="checkbox">Enable Public Leaderboards</label>
      </div>

      <div>
        <input v-model="publicLeaderboardBlacklist" style="width: 100%;" placeholder="usernames to ignore in public leaderboards separated by comma ',' (NO SPACES)" type="text">
      </div>
      <button style="margin-bottom: 300px" @click.prevent="updateScriptClicked">Update</button>
    </form>
  </div>
</template>

<script>
import store from '../../store'
import api from "../../mixins/api";
import router from "../../router";

const PLACEHOLDER_DISPLAY_LOCATION_JSON = `[
  {
    "activityName": "iron ore",
    "x": 20,
    "y": 20,
    "fontSize": 18,
    "font": "Roboto-Italic[wdth,wght]",
    "color": "mediumvioletred",
    "format": "auto"
  },
  {
    "activityName": "oranges",
    "x": 0, "y": 20,
    "fontSize": 14, "font": "Roboto[wdth,wght]",
    "color": "orchid",
    "format": "auto"
  }
]`
const USERNAME_DISPLAY_LOCATION_JSON = `{
  "x": 50,
  "y": 50,
  "fontSize": 24,
  "font": "Roboto-Italic[wdth,wght]",
  "color": "white"
}`

export default {
  name: "ScriptEditor",
  mixins: [api],
  created() {
    const id = this.$route.params.scriptId;
    const password = store.getters.getScriptPasswordById(id);

    this.getScriptValidate(id, password).then(value => {
      this.currentScript = value.data;
      this.publicLeaderboard = this.currentScript.publicLeaderboard;
      if (this.currentScript.publicLeaderboardBlacklist != null) {
        this.publicLeaderboardBlacklist = this.currentScript.publicLeaderboardBlacklist.join(",");
      }
    }).catch(reason => alert(reason));
  },
  data() {
    return {
      publicLeaderboard: false,
      publicLeaderboardBlacklist: "",
      currentScript: {},
      selectedFile: null,
      displayLocationJsonPlaceholder: PLACEHOLDER_DISPLAY_LOCATION_JSON,
      usernameDisplayLocationJsonPlaceholder: USERNAME_DISPLAY_LOCATION_JSON,
      getDisplayLocationConfig() {
        if (this.currentScript.displayLocations === null) {
          return this.displayLocationJsonPlaceholder;
        }
        return JSON.stringify(this.currentScript.displayLocations, null, 2);
      },
      getUsernameLocationConfig() {
        if (this.currentScript.usernameDisplayLocation === null) {
          return this.usernameDisplayLocationJsonPlaceholder;
        }
        return JSON.stringify(this.currentScript.usernameDisplayLocation, null, 2);
      }
    }
  },
  methods: {
    onFileSelected(event) {
      this.selectedFile = event.target.files[0];
      console.log(event)
    },
    updateScriptClicked() {
      let fileByteArray = [];
      let reader = new FileReader();
      const scriptUpdateRequest = {
        name: this.$refs.name.value,
        owner: this.$refs.owner.value,
        image: this.currentScript.image,
        imageContentType: this.currentScript.imageContentType,
        publicLeaderboard: this.publicLeaderboard,
        publicLeaderboardBlacklist: this.publicLeaderboardBlacklist.split(","),
      }
      console.log(this.$refs.publicLeaderboard);
      console.log(scriptUpdateRequest);

      try {
        scriptUpdateRequest.displayLocations = JSON.parse(this.$refs.displayLocations.value);
        scriptUpdateRequest.usernameDisplayLocation = JSON.parse(this.$refs.usernameDisplayLocation.value);
      } catch (e) {
        alert('Failed to parse Draw location json: ' + e);
        return;
      }

      if (this.selectedFile !== null) {
        reader.readAsArrayBuffer(this.selectedFile);
        reader.onload = (evt) => {
          if (evt.target.readyState !== FileReader.DONE) return;

          let array = new Uint8Array(evt.target.result);
          array.forEach(byte => fileByteArray.push(byte))
          scriptUpdateRequest.image = fileByteArray;
          scriptUpdateRequest.imageContentType = this.selectedFile.type;
          console.log(scriptUpdateRequest)
          this.updateScript(this.$route.params.scriptId, scriptUpdateRequest).then(value => {
            console.log(value);
            store.commit('addScript', value.data)
            router.push('/scripts/' + this.$route.params.scriptId)
          }).catch(err => alert(err.response.status + ": " + err.response.data));
        }
        return;
      }

      this.updateScript(this.$route.params.scriptId, scriptUpdateRequest).then(value => {
        store.commit('addScript', value.data)
        router.push('/scripts/' + this.$route.params.scriptId)
      }).catch(reason => {
        alert(reason.response.data);
      })
    }
  },
}
</script>

<style scoped>

.edit-script-title {
  font-size: xx-large;
  text-align: center;
}

input {
  font-size: large;
  font-weight: 300;
  width: 100%;
  padding: 8px 15px;
  border-radius: 5px;
  border: 1px solid #bdbdbd;

  margin-bottom: 20px;
}

textarea {
  font-family: 'Source Code Pro', serif;
  font-size: small;
  font-weight: 400;
  width: 100%;
  height: 200px;
  padding: 8px 15px;
  border-radius: 5px;
  border: 1px solid #bdbdbd;


  margin-bottom: 20px;
}

::-webkit-file-upload-button {
  color: white;
  background: #5bb6f8;
  border: none;
  padding: 7px 15px;
  font-weight: 700;
  border-radius: 3px;
  cursor: pointer;
  margin-right: 30px;
}

button {
  font-size: large;
  color: white;
  background: #5bb6f8;
  border: none;
  padding: 7px 15px;
  font-weight: 700;
  border-radius: 3px;
  cursor: pointer;
}

</style>