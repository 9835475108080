<template>
  <div style="display: flex; flex-direction: column; justify-content: center;">
    <div class="home-title">DaxTracker</div>

    <div v-if="displayedScripts().length === 0" class="no-scripts-message">
      No scripts found. Please add a script!
    </div>

    <div v-for="script in displayedScripts()" :key="script.id" class="script">
      <div style="display: flex; flex-direction: row; align-items: flex-end">
        <div @click="goToScript(script.id)" class="script-name">{{ script.name }}</div>
        <div class="script-owner">by {{ script.owner }}</div>
      </div>
      <div class="script-id">{{ script.id }}</div>
    </div>

    <router-link class="add-script-button" to="/add_script" tag="button">Add Script</router-link>

  </div>
</template>

<script>
import store from '../../store'
import api from '../../mixins/api'
import router from '../../router'

export default {
  name: 'ScriptViewer',
  mixins: [api],
  data() {
    return {
      displayedScripts: function () {
        return store.getters.getLoadedScripts;
      },
    }
  },
  created() {
    this.loadScripts();
  },
  methods: {
    goToScript(id) {
      router.push('/scripts/' + id)
    },
    loadScripts() {
      store.commit('emptyLoadedScripts');
      Object.keys(store.getters.getScripts).forEach((scriptId) => {
        this.getScriptValidate(scriptId, store.getters.getScripts[scriptId]).then(value => {
          store.commit('addLoadedScript', value.data);
        }).catch(reason => console.log(reason));
      })
    }
  },
}
</script>

<style scoped>

.home-title {
  font-size: xx-large;
  text-align: center;
  margin: 20px 0;
}

.no-scripts-message {
  color: #7d7d7d;
  font-size: large;
  font-weight: 600;
  margin-left: 15px;
  margin-bottom: 30px;
  text-align: center;
}

.script {
  margin: 15px 70px;
  padding: 10px 20px;
  border-radius: 5px;
  background-color: transparent;
}

.script:hover {
  background-color: rgba(144, 50, 177, 0.2);
  transition: all 0.3s ease;
}

.script-name {
  font-size: large;
  cursor: pointer;
}

.script-name:hover {
  color: #9400b1;
  opacity: 0.7;
  transition: all 0.4s ease;
}

.script-owner {
  font-size: small;
  margin-left: 10px;
  margin-bottom: 1px;
}

.script-id {
  font-size: x-small;
  color: #6f6f6f;
}

input {
  margin: 5px 0;
  padding: 5px 10px;
  font-size: medium;
}

button {
  margin: 5px 0;
  padding: 5px 10px;
  font-size: medium;
}

.add-script-button {
  font-size: large;
  color: white;
  background: #5bb6f8;
  border: none;
  padding: 7px 15px;
  font-weight: 700;
  border-radius: 3px;
  cursor: pointer;
  margin: 10px 50px;
}

</style>