<template>
  <div style="padding-left: 30px;padding-right: 30px" v-if="currentScript">
    <div class="script-name">{{ currentScript.name }}</div>
    <div class="script-owner">by {{ currentScript.owner }}</div>
    <div style="display: flex; flex-direction: row; align-items: center; justify-content: center; margin-bottom: 20px">
      <a class="signature-button" :href="getSignatureUrl()">Signature Image</a>
    </div>

    <highcharts :options="chartOptions"></highcharts>

    <div class="table-title">{{ parsedUsername(this.userId) }}'s Stats</div>

    <stats-table :data="tableData" :columnOptions="tableColumns" :paginated=false></stats-table>
  </div>
</template>

<script>
import store from "../../store";
import router from "../../router"
import {Chart} from 'highcharts-vue'
import StatsTable from "@/components/StatsTable";

export default {
  name: "UserView",
  created() {
    const scriptId = this.$route.params.scriptId;
    this.userId = this.$route.params.userId;
    const password = store.getters.getScriptPasswordById(scriptId);

    if (password != null) {
      this.getScriptValidate(scriptId, password).then(value => {
        this.currentScript = value.data;
      }).catch(reason => alert(reason));
    } else {
      this.getScript(scriptId).then(value => {
        this.currentScript = value.data;
      }).catch(reason => alert(reason));
    }

    this.getUserActivity(scriptId, this.userId, password).then(value => {
      this.generateSeries([value.data]);
    });

    this.getUserViaScriptCredentials(this.userId, scriptId, password).then(value => {
      console.log(value);
    })

    this.chartOptions.title.text = `Total Resources Tracked for ${this.parsedUsername(this.userId)}`
  },
  data() {
    return {
      currentScript: {},
      userId: null,
      getSignatureUrl() {
        const host = process.env.VUE_APP_API_URL
        //users/{userID}/scripts/{scriptID}/signature
        if (host != null) {
          return `${host}/api/users/${this.$route.params.userId}/scripts/${this.$route.params.scriptId}/signature`
        }
        return `/api/users/${this.$route.params.userId}/scripts/${this.$route.params.scriptId}/signature`
      },
      showSecret: false,
      showPassword: false,
      tableData: [],
      tableColumns: [],
      chartOptions: {
        title: {text: 'Total Resources Tracked'},
        subtitle: {text: 'All Users'},
        yAxis: {title: {text: 'Units'}},
        xAxis: {
          type: 'datetime',
          labels: {
            format: '{value:%b %d %Y}',
            rotation: 45,
          },
        },
        series: [],
        chart: {zoomType: 'x'},
      }
    }
  },
  methods: {
    generateSeries(data) {
      if (data == null) return [];

      // ===== GRAPH GENERATION =====
      // Transform to timestamp[resources]
      const resources = [];
      data.forEach(activity => {
        resources.push(activity.resources);
      })
      const aggregate = this.aggregateTimeseries(resources);
      this.chartOptions.series = this.transformTimeseriesToGraphable(aggregate);

      // ===== TABLE GENERATION =====
      const activities = [];
      data.forEach(activity => {
        activities.push({
          userID: activity.userID,
          banana: 5,
          resources: this.addAllTimeseries(activity.resources),
        })
      });

      const columns = [];
      const resourceMapping = this.transformTimeseriesToResourceMapping(aggregate);
      Object.keys(resourceMapping).forEach(resourceName => {
        columns.push({
          resourceName: resourceName,
        })
      });

      this.tableData = activities;
      this.tableColumns = columns;
    },
    goToEdit() {
      router.push('/scripts/' + this.$route.params.scriptId + "/edit")
    },
    toggleShowSecret() {
      this.showSecret = !this.showSecret;
    },
    toggleShowPassword() {
      this.showPassword = !this.showPassword;
    }
  },
  components: {
    StatsTable,
    highcharts: Chart,
  }
}
</script>

<style scoped>

.script-name {
  font-size: 48px;
  text-align: center;
}

.script-owner {
  font-size: x-large;
  text-align: center;
  margin-bottom: 10px;
}

.signature-button {
  font-size: small;
  color: #6f6f6f;
  text-decoration: none;
  margin-bottom: 40px;
}

.table-title {
  font-size: 20px;
  text-align: center;
  margin: 80px 0 20px 0;
}

</style>