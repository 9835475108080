import Vue from 'vue'
import App from './App.vue'
import router from './router'
import state from './store'
import axios from 'axios'
import VueAxios from 'vue-axios'
import HighchartsVue from 'highcharts-vue'
import api from './mixins/api'
import compute from './mixins/compute'
import Buefy from 'buefy'
import 'buefy/dist/buefy.css'

Vue.config.productionTip = false;
Vue.use(VueAxios, axios);
Vue.use(HighchartsVue, {tagName: 'charts'});
Vue.use(Buefy, { defaultIconPack: 'fal' });
Vue.mixin(api);
Vue.mixin(compute);
Vue.use(require('vue-moment'));

new Vue({
    router,
    state,
    render: h => h(App)
}).$mount('#app');
