<template>
  <div>
    <div class="nav-bar">
      <div v-on:click="goHome" class="home-button">Home</div>
    </div>
    <router-view></router-view>
    <div v-if="isHome()" class="background"></div>
  </div>
</template>

<script>

import router from './router'

export default {
  name: 'App',

  components: {},

  methods: {
    goHome() {
      router.push('/')
    },
    isHome() {
      console.log(this.$route.name);
      return this.$route.name === 'ScriptsHome' || this.$route.name === 'CreateScript' || this.$route.name === 'AddScript';
    }
  },

  data: () => ({
    //
  }),
};
</script>

<style>
* {
  font-family: 'Quicksand', sans-serif;
  color: #3d3d3d;
}


.background {
  position: absolute;
  width: 100%;
  bottom: 0;
  opacity: 0.7;

  content: url('~@/assets/background.jpg');
  z-index: -1;

  -webkit-mask-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 1)), to(rgba(0, 0, 0, 0)));
  mask-image: linear-gradient(to top, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));

}


.nav-bar {
  font-size: large;
  font-weight: 600;
  margin-bottom: 20px;
  border-bottom: 1px solid #dedede;
  padding: 25px 0 25px 5px;
  background-color: white;
}

.nav-bar div {
  display: inline;
  cursor: pointer;

}

.home-button {
  margin-left: 30px;
}

</style>