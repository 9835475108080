<template>
  <div style="display: flex; flex-direction: column; align-items: center;">
    <div style="font-size: xx-large; margin-bottom: 10px">Welcome!</div>
    <form>
      <label class="column add-script-contents" style="font-size: small; text-align: center;">
        This is NOT your daxwalker Api Key!
        <input ref="apiKey" placeholder="API Key">
        <input ref="password" placeholder="Password">

        <button @click.prevent="addScript">Login</button>
        <label style="margin: 60px 0 10px 0; text-align: center; font-size: small">Don't have a script? Create one!</label>
        <router-link to="/create_script" tag="button">Create New Script</router-link>
      </label>
    </form>
  </div>
</template>


<script>
import store from '../../store'
import api from '../../mixins/api'
import router from '../../router'

export default {
  name: 'AddScript',
  mixins: [api],
  data() {
    return {
      scripts: function () {
        return store.state.scripts
      },
    }
  },
  methods: {
    addScript() {
      let apiKey = this.$refs.apiKey.value;
      let password = this.$refs.password.value;
      this.getScriptValidate(apiKey, password).then(getScriptResponse => {
        let script = getScriptResponse.data;
        script.secret = password;
        store.commit('addScript', script);
        router.push('/');
      }).catch(reason => alert(reason));
    },
  },
}
</script>


<style scoped>
.add-script-contents {
  max-width: 300px;
}

.column {
  display: flex;
  flex-direction: column;
}

input {
  font-size: large;
  font-weight: 300;
  width: 100%;
  padding: 8px 15px;
  border-radius: 5px;
  border: 1px solid #bdbdbd;
  background-color: rgba(255,255,255, 0.7);

  margin-bottom: 20px;
}

button {
  font-size: large;
  color: white;
  background: #5bb6f8;
  border: none;
  padding: 7px 15px;
  font-weight: 700;
  border-radius: 3px;
  cursor: pointer;
}

</style>